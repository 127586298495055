import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faGraduationCap, faStairs, faMedal, faDownload } from '@fortawesome/free-solid-svg-icons'
import Seo from '../../components/Seo'
import * as styles from '../../styles/pages/course.module.scss'

export default function CoursePage({ data }) {
  const course = data.sanityCourse
  const siteSetting = data.sanitySiteSettings
  const nextStepLinks = siteSetting?.nextStepLinks;

  return (
    <main className={styles.coursePage}>
      <Seo title={course.title} pathname={course.slug.current} />
      
      <p className={styles.courseBreadcrumb}>
        <Link to='/'>Home</Link> {`>`}&nbsp;
        <Link to='/courses'>Courses</Link> {`>`}&nbsp;
        {course.title}
      </p>

        <div className={styles.courseBlock}>
            <GatsbyImage image={getImage(course.featuredImage?.asset)} alt={course.title} className={styles.courseImage}/>
            <h1 className={styles.courseTitle}>{course.title}</h1>

            <div className={styles.courseContent}>
                <div className={styles.courseInfo}>
                  <ul>
                    {course.courseStartDates && (
                      <li>
                        <FontAwesomeIcon icon={faCalendar} style={{ marginRight: '.5rem'}}/>
                        {course.courseStartDates.map((date) => date.learningMethod.title).join(' | ')}
                      </li>
                    )}
                    {course.nqfLevel && 
                      <li>
                        <FontAwesomeIcon icon={faStairs} style={{ marginRight: '.5rem'}}/>
                        NQF Level: {course.nqfLevel}
                      </li>}
                    {course.credits && 
                      <li>
                        <FontAwesomeIcon icon={faGraduationCap} style={{ marginRight: '.5rem'}}/>
                        Credits: {course.credits}
                      </li>}
                    {course.accreditation.length > 0 && (
                      <li>
                        <FontAwesomeIcon icon={faMedal} style={{ marginRight: '.5rem'}}/>
                        {course.accreditation.map((accr) => accr.title).join(' | ')}
                      </li>
                    )}
                  </ul>
                </div>

                {/* Short Description */}
                <h3>{course.shortDescription}</h3>

                {/* Description */}
                {course.description && (
                  <div>
                    <h1>Description</h1>
                    {course.description?.map((paragraph, index) => (
                        <p style={{ margin: '1rem'}} key={index}>
                            {paragraph.children.map((text, index) => text.text).join(' ')}
                        </p>
                    ))}
                  </div>
                )}

                {/* Career oppurtunities */}
                {course.careerOpportunities && (
                  <>
                    <h1>Career Oppurtunties</h1>
                    <div style={{ margin: '1rem'}}>
                    {course.careerOpportunities?.map((data) => (
                      <div style={{ margin: '.5rem 1rem'}}>
                        {data.children.map((oppurtunity) => (
                          <ul>
                            <li>{oppurtunity.text}</li>
                          </ul>
                        ))}
                      </div>
                    ))}
                    </div>
                  </>
                )}

                {/* Course Information eg. Entrance Requirements */}
                {course.courseInformation.length > 0 && (
                  <div style={{ marginBottom: '1rem'}}>
                    {course.courseInformation.map((item, index) => (
                      <>
                        <h1>{item.title}</h1>
                        <div  style={{ margin: '.5rem 1rem'}} key={index}>
                          <ul style={{ margin: '.5rem 1rem'}}>
                            {item.details.map((detail, detailIndex) => (
                              <li key={detailIndex}>{detail.children[0].text}</li>
                            ))}
                          </ul>
                        </div>
                      </>
                    ))}
                  </div>
                )}

                {/* Factsheet Download button */}
                {course.factsheets.length > 0 && (
                <button 
                  className={styles.downloadButton}
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = course.factsheets[0].asset.url; 
                    link.download = 'factsheet.pdf'; 
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                >
                  <FontAwesomeIcon icon={faDownload} style={{ marginRight: '.5rem'}}/>
                  <span style={{ fontWeight: 'bold' }}>Factsheet</span>
                </button>
                )}

                {/* Apply Now button */}
                {nextStepLinks && nextStepLinks.length > 0 && (
                      <button className={styles.applyButton}>
                          {nextStepLinks.find(link => link.text === 'Apply Now') && (
                              <Link
                                  href={nextStepLinks.find(link => link.text === 'Apply Now').link.href}
                                  style={{ fontWeight: 'bold', textDecoration: 'none' }}
                                  target={nextStepLinks.find(link => link.text === 'Apply Now').link.blank ? "_blank" : "_self"}
                              >
                                  Apply Now
                              </Link>
                          )}
                      </button>
                  )}

            </div>
        </div>
    </main>
  )
}

export const query = graphql`
  query CourseQuery($id: String) {
    sanityCourse(id: { eq: $id }) {
        id
        featuredImage {
          asset {
            gatsbyImageData
          }
        }
        title
        shortDescription
        saqaId
        learningProgrammeId
        nqfLevel
        credits
        description {
            children {
              text
            }
          }
        studyFields {
          title
        }
        courseInformation {
          title
          details {
            children {
              text
            }
          }
        }
        accreditation {
          title
        }
        slug {
          current
        }
        courseStartDates {
          learningMethod {
              title
          }
          duration
          pricing {
            showPrice
            paymentOptions {
              price
            }
          }
        }
        careerOpportunities {
          children {
            text
          }
        }
        factsheets {
          asset {
            url
          }
        }
      }
      sanitySiteSettings {
        id
        nextStepLinks {
          text
          link {
            _key
            _type
            linkType
            href
            blank
            _rawInternalLink
          }
        }
      }
  }
`
